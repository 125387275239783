/* Removed :link from the list to help cut down on writing time and prevent the need for extra specificity for classes */
a,
a:visited {
	text-decoration: none;
}
a:hover,
a:active,
a:focus {
	color: #4d4d4d;
	text-decoration: none;
}
a:active,
a:focus {
	outline: 0;
}

/* S MacNevin additions begin */

#selSofts {
	display: none;
}

#selMetals {
	display: none;
}

#selCurrencies {
	display: none;
}

#selGrains {
	display: none;
}

#selFinancials {
	display: none;
}

#selEnergies {
	display: none;
}

#selLivestock {
	display: none;
}

td .marketview_symbol sync-name {
	font-family: monospace;
	font-size: 1.4rem;
}

/* S MacNevin additions END */

/*	------------------------------------------
	Markets
	----------------------------------------- */
.hm-markets {
}
.marketviewWrpr {
	color: #444444;
	font-size: 0.938em;
	height: 270px; /*overflow: auto;*/
	overflow-x: hidden;
	border-bottom: 1px solid #c7c7c7;
}
.marketview_date {
	padding: 0 0.1rem;
	font-size: 1.1rem;
	display: none;
}
.marketview_provider {
	font-size: 0.8em;
	display: none;
}
.marketview_topheader {
	padding: 0 12px;
}
.marketview_quoteboard {
	width: 100%;
}

.marketview_quoteboard .marketview_title {
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 12px;
	font-size: 1.3rem;
	font-weight: 450;
	border-radius: 5px;
	color: white;
}
.marketview_quoteboard .marketview_title a {
	pointer-events: none;
	color: white;
}
.marketview_quoteboard .marketview_title a:link,
.marketview_quoteboard .marketview_title a:visited {
	text-decoration: none;
}
.marketview_quoteboard .marketview_data {
	display: table;
	width: 100%;
}
.marketview_quoteboard .marketview_data .marketview_symbol {
	display: table-cell;
	vertical-align: middle;
	padding: 0 12px;
}
.marketview_quoteboard .marketview_data .marketview_quote {
	display: table-cell;
	vertical-align: middle;
	padding: 0 12px;
}
.marketview_quoteboard .marketview_data .marketview_changeDn {
	display: table-cell;
	vertical-align: middle;
	padding: 0 12px;
}
#marketingTabs.ui-tabs .ui-tabs-panel {
	padding: 0;
}
.marketview_TRe td,
.marketview_TRe td {
	padding: 2px 0;
}

#divFutures:first-child .bcMarketOverview {
	width: 100%;
}
#divFutures:first-child .bcMarketOverview:first-child .bcOverviewTR {
	display: block;
	width: 100%;
}
#divFutures:first-child .bcOverviewTR:first-child .bcOverviewTD {
	display: inline-block;
}
.bcOverviewOverviewBox {
}
.bcOverviewOverviewBox td,
.bcOverviewCommodityBoxTR td {
	padding: 5px 10px;
}
.bcOverviewOverviewBoxTD {
}
.bcOverviewOverviewBox,
.bcOverviewCommodityBox {
	border: 1px solid #e8e8e8;
}
.bcOverviewCommodityBox .bcOverviewCommodityBox {
	border: 0 none;
}
.bcOverviewOtherCommodityBoxTD {
	padding: 10px 0 0;
}
.bcOverviewCommodityBox .bcOverviewSelect {
	max-width: 100px;
}

.bcOverviewDefault {
	width: 100%;
	max-width: 786px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.bcOverviewHeaderDefault {
	font-family: "FaktPro-Medium", Arial, sans-serif;
	padding: 10px 0 5px;
	border-bottom: 1px solid #25528a;
	margin-bottom: 10px;
	color: #25528a;
}
.bcOverviewDefault .bcOverviewDefaultTR {
}
.bcOverviewDefault .bcOverviewDefaultTD {
	width: 33.333%;
	padding: 10px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	vertical-align: top;
}
.bcOverviewDefault .bcOverviewDefaultTD .marketview_TRe td,
.bcOverviewDefault .bcOverviewDefaultTD .marketview_TRo td {
	padding: 5px 0;
	font-size: 0.875em;
}
.bcOverviewDefault .bcOverviewDefaultTD .marketview_symbol a {
	padding-left: 10px;
}
.bcOverviewDefault
	.bcOverviewDefaultTD
	.marketview_quoteboard
	.marketview_title {
	padding: 4px 12px 0 8px;
}
.bcOverviewDefault .bcOverviewDefaultTD .marketview_header td {
	font-size: 0.875em;
	padding-left: 10px;
}

#hp_right td.marketview_symbol a:link,
#hp_right td.marketview_symbol a:visited,
#hp_right td.marketview_symbol a:hover,
#hp_right td.marketview_symbol a:active {
	color: #000;
}
td.marketview_changeUp,
td.marketview_changeDn {
}

td.marketview_changeUp {
	color: Green;
}
td.marketview_changeDn {
	color: Red;
}
.tooltip {
	border: 1px solid #999999;
	padding: 1px;
	margin: 1px;
	font-size: 8pt;
	background-color: #ffffcc;
	font-family: Arial;
	width: 100px;
}

.qtiOverviewTitle {
	display: block;
	float: left;
	margin: 0px 0px 0px 0px;
	font-size: 2rem;
}
.qtiOverviewSelect {
	display: block;
	float: left;
	margin: 5px 10px 5px 10px;
}

td {
    padding: 0;
}
