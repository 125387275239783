.cash-bid-container {
    margin-top: 1rem;
}

.cash-bid-table_commodityIcon {
    max-width: 3rem;
    max-height: 3rem;
}

.cash-bid-table_title {
    display: flex;
    align-items: center;
    padding: 0.5rem;
}

.cash-bid-table_title> :nth-child(2) {
    margin-left: 0.5rem;
    font-size: 1.5rem;
}

.cash-bid-table {
    table-layout: fixed;
}

.cash-bid-table td {
    width: 100%;
}

.highlighted {
    font-weight: 600;
}

.negative {
    color: red;
}

.positive {
    color: green;
}