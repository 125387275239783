#update-card{
  border-radius:"15px !important";
  justify-content: left !important;
}

td{
  text-align:left !important;
}

.table-header{
  width: 35% !important;
}