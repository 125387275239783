section .marketview_date {
	padding: 0 0.1rem;
	font-size: 1em;
	font-weight: bold;
	display: inline !important;
	color: darkblue;
}
section .marketview_provider {
	font-size: 1em;
	display: inline !important;
}
