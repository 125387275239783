.hamburger {
	cursor: pointer;
	width: 24px;
	height: 24px;
	transition: all 0.25s;
	position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
	position: absolute;
	top: 0;
	left: 0;
	width: 24px;
	height: 2px;
	background: black;
	transform: rotate(0);
	transition: all 0.5s;
}

.hamburger-middle {
	transform: translateY(7px);
}

.hamburger-bottom {
	transform: translateY(14px);
}

.open {
	transform: rotate(90deg);
	transform: translateY(0px);
}

.open .hamburger-top {
	transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
	opacity: 0;
}

.open .hamburger-bottom {
	transform: rotate(-45deg) translateY(6px) translate(-6px);
}
