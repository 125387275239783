.redirect {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	margin-top: 25vmin;
}

.cash-bid-table thead {
	border-color: #fff;
}

.cashbid__appLink {
	margin-top: 2rem;
	text-align: right;
	font-weight: 600;
	padding-right: 1rem;
}

.cashbid__appLink > a {
	padding-left: 5px;
}

@media (max-width: 792px) {
	.redirect {
		margin-top: 15vmin;
	}
	.hidden-if-tab-mobile {
		display: none;
	}
}

@media (max-width: 468px) {
	.redirect {
		margin-top: 10vmin;
	}
	.hidden-if-tab-mobile {
		display: none;
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loading-spinner {
	width: 50px;
	height: 50px;
	border: 5px solid #f3f3f3; /* Light grey */
	border-top: 5px solid #383636; /* Black */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}
